import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import customerApi from '../api/customer'
import { parseError } from '../utils'

const initialState = {
  regulars: { data: null, error: null },
  partners: { data: null, error: null },
  ecommerce: { data: null, error: null }
}

export const fetchCustomers = createAsyncThunk('customers/fetch', async () => {
  const response = await customerApi.getCustomers()

  if (!response.ok) {
    const apiError = parseError(response)
    const payload = { type: 'error' }
    if (apiError) {
      payload.error = apiError
    }
    return payload
  }

  return {
    type: 'success',
    data: response.data.payload
  }
})

export const fetchPartners = createAsyncThunk(
  'customers/partners/fetch',
  async () => {
    const response = await customerApi.getPartners()

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
      return payload
    }

    return {
      type: 'success',
      data: response.data.payload
    }
  }
)

export const fetchEcommerce = createAsyncThunk(
  'customers/ecommerce/fetch',
  async () => {
    const response = await customerApi.getECommerce()

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
      return payload
    }

    return {
      type: 'success',
      data: response.data.payload
    }
  }
)

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        if (action.payload.type === 'success') {
          state.regulars.data = action.payload.data
          state.regulars.error = null
        } else {
          state.regulars.error = action.payload.error
          state.regulars.data = null
        }
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        if (action.payload.type === 'success') {
          state.partners.data = action.payload.data
          state.partners.error = null
        } else {
          state.partners.error = action.payload.error
          state.partners.data = null
        }
      })
      .addCase(fetchEcommerce.fulfilled, (state, action) => {
        if (action.payload.type === 'success') {
          state.ecommerce.data = action.payload.data
          state.ecommerce.error = null
        } else {
          state.ecommerce.error = action.payload.error
          state.ecommerce.data = null
        }
      })
  }
})

export const customerActions = customersSlice.actions

export default customersSlice.reducer
