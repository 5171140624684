import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { parseError } from '../utils'
import orgsApi from '../api/orgs'

const initialState = {
  branches: null,
  areas: null,
  regions: null,
  systems: null
}

export const fetchBranches = createAsyncThunk('orgs/branches', async () => {
  const response = await orgsApi.getBranches()

  if (!response.ok) {
    const apiError = parseError(response)
    const payload = { type: 'error' }
    if (apiError) {
      payload.error = apiError
    }
    return payload
  }

  return response.data
})

const orgsSlice = createSlice({
  name: 'orgs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchBranches.fulfilled, (state, action) => {
      state.branches = action.payload?.payload
    })
  }
})

export const orgsActions = orgsSlice.actions

export default orgsSlice.reducer
