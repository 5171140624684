import React, { createContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {
  GetQuote,
  Orders,
  Invoice,
  Receipts,
  Users,
  Login,
  CustomerInfo,
  PackageSection,
  InvoiceView,
  ReceiptView,
  Tracking,
  ShipmentType,
  PackageType,
  FishSnail,
  Document,
  AccountSettings,
  Summary,
  Quotes,
  ShipmentSection,
  Payment,
  Transactions,
  PublicQuote,
  CenterManifest,
  RemitCashTransactions,
  Dashboard,
  OrderDrafts,
  SalesForce,
  SalesForceAgent
} from './pages'
import ProtectedRoute from './helpers/protectedRoute'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import IsUserRedirect from './helpers/isUserRedirect'
import { useSelector } from 'react-redux'
import ROUTES from './constants/routes'
import Shipments from './pages/shipments/shipments'
import ManifestSheet from './pages/manifest/ManifestSheet'
import TransferManifest from './pages/manifest/TransferManifest'
import IndividualTransferManifest from './pages/manifest/IndividualTransferManifest'
import CreateManifest from './pages/manifest/CreateManifest'
import IndividualCenterManifest from './pages/manifest/IndividualCenterManifest'
import CreateDoorstep from './pages/manifest/CreateDoorstep'
import DoorstepManifest from './pages/manifest/DoorStepManifest'
import IndividualDoorstepManifest from './pages/manifest/IndividualDoorstepManifest'
import CenterManifestSheet from './pages/manifest/CenterManifestSheet'
import DoorManifestSheet from './pages/manifest/DoorManifestSheet'
import InternationalManifest from './pages/manifest/InternationalManifest'
import IndividualInternationalManifest from './pages/manifest/IndividualInternationalManifest'
import InternationalManifestSheet from './pages/manifest/InternationalManifestSheet'
import CreateOrderLayout from './containers/CreateOrderLayout'

export const AppContext = createContext()

function App () {
  const { isLoggedIn } = useSelector(state => state.auth)

  const createOrderRoutes = [
    {
      path: 'SHIPMENT_TYPE',
      element: ShipmentType
    },
    {
      path: 'CUSTOMER_INFO',
      element: CustomerInfo
    },
    {
      path: 'PACKAGE_TYPE',
      element: PackageType
    },
    {
      path: 'PACKAGE_SECTION',
      element: PackageSection
    },
    {
      path: 'FISH_SNAIL',
      element: FishSnail
    },
    {
      path: 'DOCUMENT',
      element: Document
    },
    {
      path: 'SHIPMENT_SECTION',
      element: ShipmentSection
    },
    {
      path: 'ORDER_SUMMARY',
      element: Summary
    },
    {
      path: 'PAYMENT',
      element: Payment
    }
  ]

  return (
    <AppContext.Provider value={{}}>
      <ToastContainer />

      <Routes>
        <Route
          exact
          path={ROUTES.HOME.path}
          element={
            isLoggedIn ? (
              <Navigate replace to={ROUTES.DASHBOARD.path} />
            ) : (
              <Navigate replace to={ROUTES.LOGIN.path} />
            )
          }
        />
        {/* PUBLIC ROUTES */}
        <Route
          path={ROUTES.LOGIN.path}
          element={
            <IsUserRedirect>
              <Login metaTitle={ROUTES.LOGIN.metaTitle} />
            </IsUserRedirect>
          }
        />
        <Route
          path={ROUTES.PUBLIC_QUOTE.path}
          element={<PublicQuote metaTitle={ROUTES.PUBLIC_QUOTE.metaTitle} />}
        />
        {/* PUBLIC ROUTES END */}

        {/* PROTECTED ROUTES */}
        <Route element={<ProtectedRoute />}>
          <Route
            exact
            path={ROUTES.DASHBOARD.path}
            element={<Dashboard metaTitle={ROUTES.DASHBOARD.metaTitle} />}
          />

          {/* QUOTES ROUTES */}
          <Route path={ROUTES.QUOTES.path}>
            <Route
              index
              element={<Quotes metaTitle={ROUTES.QUOTES.metaTitle} />}
            />
            <Route
              path={ROUTES.QUOTES.GET_QUOTE.path}
              element={
                <GetQuote metaTitle={ROUTES.QUOTES.GET_QUOTE.metaTitle} />
              }
            />
          </Route>
          {/* QUOTES ROUTES END */}

          {/* ORDERS ROUTES */}
          <Route path={ROUTES.ORDERS.path}>
            <Route
              index
              element={<Orders metaTitle={ROUTES.ORDERS.metaTitle} />}
            />
            <Route
              path={ROUTES.ORDERS.DRAFTS.path}
              element={
                <OrderDrafts metaTitle={ROUTES.ORDERS.DRAFTS.metaTitle} />
              }
            />
            {/* NEW ORDER ROUTES */}
            <Route
              element={<CreateOrderLayout />}
              path={ROUTES.ORDERS.CREATE_ORDER.path}
            >
              {createOrderRoutes.map(route => {
                return (
                  <Route
                    key={route.path}
                    path={ROUTES.ORDERS.CREATE_ORDER[route.path].path}
                    element={
                      <route.element
                        metaTitle={
                          ROUTES.ORDERS.CREATE_ORDER[route.path].metaTitle
                        }
                      />
                    }
                  />
                )
              })}
            </Route>
            {/* NEW ORDER END */}

            {/* UPDATE ORDER ROUTES */}
            <Route
              element={<CreateOrderLayout />}
              path={ROUTES.ORDERS.CREATE_ORDER.UPDATE_ORDER.path}
            >
              {createOrderRoutes.map(route => {
                return (
                  <Route
                    key={route.path}
                    path={ROUTES.ORDERS.CREATE_ORDER[route.path].path}
                    element={
                      <route.element
                        metaTitle={
                          ROUTES.ORDERS.CREATE_ORDER[route.path].metaTitle
                        }
                      />
                    }
                  />
                )
              })}
            </Route>
            {/* UPDATE ORDER ROUTES END */}
          </Route>
          {/* ORDERS ROUTES END */}

          <Route
            path={ROUTES.SHIPMENTS.path}
            element={<Shipments metaTitle={ROUTES.SHIPMENTS.metaTitle} />}
          />

          {/* RECEIPTS ROUTES */}
          <Route path={ROUTES.RECEIPTS.path}>
            <Route
              index
              element={<Receipts metaTitle={ROUTES.RECEIPTS.metaTitle} />}
            />
            <Route
              path={ROUTES.RECEIPTS._ID.path}
              element={
                <ReceiptView metaTitle={ROUTES.RECEIPTS._ID.metaTitle} />
              }
            />
          </Route>
          {/* RECEIPTS ROUTES END */}

          {/* INVOICES ROUTES */}
          <Route path={ROUTES.INVOICES.path}>
            <Route
              index
              element={<Invoice metaTitle={ROUTES.INVOICES.metaTitle} />}
            />
            <Route
              path={ROUTES.INVOICES._ID.path}
              element={
                <InvoiceView metaTitle={ROUTES.INVOICES._ID.metaTitle} />
              }
            />
          </Route>
          {/* INVOICES ROUTES END */}

          {/* TRANSACTIONS ROUTES */}
          <Route path={ROUTES.TRANSACTIONS.path}>
            <Route
              index
              element={
                <Transactions metaTitle={ROUTES.TRANSACTIONS.metaTitle} />
              }
            />
            <Route
              path={ROUTES.TRANSACTIONS.REMIT_CASH_TRANSACTIONS.path}
              element={
                <RemitCashTransactions
                  metaTitle={
                    ROUTES.TRANSACTIONS.REMIT_CASH_TRANSACTIONS.metaTitle
                  }
                />
              }
            />
          </Route>
          {/* TRANSACTIONS ROUTES END */}

          {/* SALESFORCE ROUTES */}
          <Route path={ROUTES.SALESFORCE.path}>
            <Route
              index
              element={<SalesForce metaTitle={ROUTES.SALESFORCE.metaTitle} />}
            />
            <Route
              path={ROUTES.SALESFORCE.SALESFORCE_AGENT.path}
              element={
                <SalesForceAgent
                  metaTitle={ROUTES.SALESFORCE.SALESFORCE_AGENT.metaTitle}
                />
              }
            />
          </Route>
          {/* SALESFORCE ROUTES END */}

          <Route
            path={ROUTES.SALESFORCE.path}
            element={<SalesForce metaTitle={ROUTES.SALESFORCE.metaTitle} />}
          />

          <Route
            path={ROUTES.USERS.path}
            element={<Users metaTitle={ROUTES.USERS.metaTitle} />}
          />

          <Route
            path={ROUTES.TRACK.path}
            element={<Tracking metaTitle={ROUTES.TRACK.metaTitle} />}
          />
          <Route
            path={ROUTES.ACCOUNT_SETTINGS.path}
            element={
              <AccountSettings metaTitle={ROUTES.ACCOUNT_SETTINGS.metaTitle} />
            }
          />
          {/* MANIFEST ROUTES */}
          <Route path={ROUTES.MANIFEST.path}>
            <Route
              index
              element={<CenterManifest metaTitle={ROUTES.MANIFEST.metaTitle} />}
            />
            <Route
              path={ROUTES.MANIFEST.CENTER_MANIFEST.path}
              element={
                <CenterManifest
                  metaTitle={ROUTES.MANIFEST.CENTER_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.INTERNATIONAL_MANIFEST.path}
              element={
                <InternationalManifest
                  metaTitle={ROUTES.MANIFEST.INTERNATIONAL_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.PRINT_MANIFEST.path}
              element={
                <ManifestSheet
                  metaTitle={ROUTES.MANIFEST.PRINT_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.TRANSFER_MANIFEST.path}
              element={
                <TransferManifest
                  metaTitle={ROUTES.MANIFEST.TRANSFER_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.DOORSTEP_MANIFEST.path}
              element={
                <DoorstepManifest
                  metaTitle={ROUTES.MANIFEST.DOORSTEP_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.CREATE_MANIFEST.path}
              element={
                <CreateManifest
                  metaTitle={ROUTES.MANIFEST.CREATE_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.CREATE_DOORSTEP.path}
              element={
                <CreateDoorstep
                  metaTitle={ROUTES.MANIFEST.CREATE_DOORSTEP.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.SINGLE_CENTER_MANIFEST.path}
              element={
                <IndividualCenterManifest
                  metaTitle={ROUTES.MANIFEST.SINGLE_CENTER_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.SINGLE_INTERNATIONAL_MANIFEST.path}
              element={
                <IndividualInternationalManifest
                  metaTitle={
                    ROUTES.MANIFEST.SINGLE_INTERNATIONAL_MANIFEST.metaTitle
                  }
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.SINGLE_TRANSFER_MANIFEST.path}
              element={
                <IndividualTransferManifest
                  metaTitle={ROUTES.MANIFEST.SINGLE_TRANSFER_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.SINGLE_DOORSTEP_MANIFEST.path}
              element={
                <IndividualDoorstepManifest
                  metaTitle={ROUTES.MANIFEST.SINGLE_DOORSTEP_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.PRINT_CENTER_MANIFEST.path}
              element={
                <CenterManifestSheet
                  metaTitle={ROUTES.MANIFEST.PRINT_CENTER_MANIFEST.metaTitle}
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.PRINT_INTERNATIONAL_MANIFEST.path}
              element={
                <InternationalManifestSheet
                  metaTitle={
                    ROUTES.MANIFEST.PRINT_INTERNATIONAL_MANIFEST.metaTitle
                  }
                />
              }
            />
            <Route
              path={ROUTES.MANIFEST.PRINT_DOOR_MANIFEST.path}
              element={
                <DoorManifestSheet
                  metaTitle={ROUTES.MANIFEST.PRINT_DOOR_MANIFEST.metaTitle}
                />
              }
            />
          </Route>
          {/* MANIFEST ROUTES END */}
        </Route>

        {/* PROTECTED ROUTES END */}
      </Routes>
    </AppContext.Provider>
  )
}

export default App
