import React from 'react'
import './loader.css'
import { twMerge } from 'tailwind-merge'

const Loader = ({ page = true, size = 'lg' }) => {
  return (
    <div
      className={twMerge(
        'flex justify-center items-center m-auto',
        page ? 'h-screen max-h-[500px]' : 'h-fit',
        size === 'sm' ? 'scale-[0.3]' : ''
      )}
    >
      <div className='lds-spinner'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Loader
